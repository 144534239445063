import React from 'react'
import ReactMarkdown from 'react-markdown'

import Tick from '../assets/images/tick.inline.svg'

const Markdown = ({ children, accentColor, ...props }) => (
  <ReactMarkdown
    components={{
      ul: ({ node, ordered, ...nodeProps }) => (
        <ul className="list-disc" {...nodeProps} />
      ),
      li: ({ node, ordered, children, ...nodeProps }) => (
        <li className="flex items-start mb-4" {...nodeProps}>
          <Tick
            className={`mr-3 text-${
              accentColor === 'green' ? 'purple' : 'green'
            }-500 stroke-current flex-shrink-0 w-5 h-5 mt-1`}
          />
          <span>{children}</span>
        </li>
      ),
      p: ({ node, ...nodeProps }) => <p className="mb-4" {...nodeProps} />,
      a: ({ node, ...nodeProps }) => (
        <a className={`font-medium text-${accentColor}-500`} {...nodeProps} />
      ),
    }}
    {...props}
  >
    {children}
  </ReactMarkdown>
)

export default Markdown
