const getPath = (page) => {
  if (!!page.typeDePage) {
    switch (page.typeDePage) {
      case 'formules':
        return `/formules/${page.slug}/`
      case 'faq':
        return `/faq/${page.slug}/`
      case 'guides':
        return `/guides/${page.slug}/`
      default:
        return page.slug === 'home' ? '/' : `/${page.slug}/`
    }
  }

  if (!!page.categorie) {
    return `/${page.categorie.slug}/${page.slug}`
  }
  return page.slug
}

module.exports = getPath
