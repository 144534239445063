import React from 'react'
import slugify from 'slugify'
import { StructuredText as OriginalStructuredText } from 'react-datocms'
import {
  renderRule,
  isHeading,
  isList,
  isParagraph,
  isLink,
} from 'datocms-structured-text-utils'
import { render } from 'datocms-structured-text-to-plain-text'
import Link from './Link'
import getPath from '../../getPath'

const getId = (node) => {
  try {
    const id = slugify(render(node), {
      strict: true,
      lower: true,
      locale: 'fr',
    })
    return id
  } catch (e) {
    return null
  }
}

const StructuredText = (props) => {
  if (props.data.links) {
    props.data.links = props.data.links.map((link) => {
      if (link.originalId) {
        return {
          ...link,
          id: link.originalId,
        }
      }
    })
  }
  if (props.data.blocks) {
    props.data.blocks = props.data.blocks.map((block) => {
      if (block.originalId) {
        return {
          ...block,
          id: block.originalId,
        }
      }
    })
  }
  return (
    <OriginalStructuredText
      renderLinkToRecord={({ record, children }) => {
        return <Link to={getPath(record)}>{children}</Link>
      }}
      customRules={[
        renderRule(
          isHeading,
          ({ node, adapter: { renderNode }, children, key }) => {
            let additionalProps = {
              className: 'font-bold',
            }
            switch (node.level) {
              case 2:
                const id = getId(node)
                additionalProps.className =
                  additionalProps.className + ' text-4xl my-10'
                if (!!id) {
                  additionalProps.id = id
                }
              case 3:
                additionalProps.className =
                  additionalProps.className + ' text-2xl my-6'
              case 4:
                additionalProps.className =
                  additionalProps.className + ' text-xl my-4'
              default:
            }
            return renderNode(
              `h${node.level}`,
              { key, ...additionalProps },
              children
            )
          }
        ),
        renderRule(
          isList,
          ({ node, adapter: { renderNode }, children, key }) => {
            return renderNode(
              node.style === 'bulleted' ? 'ul' : 'ol',
              {
                key,
                className: `${
                  node.style === 'bulleted' ? 'list-disc' : 'list-decimal'
                }`,
              },
              children
            )
          }
        ),
        renderRule(
          isParagraph,
          ({ adapter: { renderNode }, children, key, ancestors }) => {
            return renderNode(
              'p',
              {
                key,
                className:
                  ancestors.findIndex(({ type }) => type === 'listItem') === -1
                    ? 'mb-6'
                    : '',
              },
              children
            )
          }
        ),
        renderRule(
          isLink,
          ({ adapter: { renderNode }, children, key, node }) => {
            let additionalProps = {}
            if (node.meta && node.meta.length) {
              const target = node.meta.find((meta) => meta.id === 'target')
              if (target) {
                additionalProps.target = target.value
              }
            }
            return renderNode(
              'a',
              {
                key,
                className: 'text-green-500',
                href: node.url,
                ...additionalProps,
              },
              children
            )
          }
        ),
      ]}
      {...props}
    />
  )
}

export default StructuredText
