import React, { useEffect } from 'react'

const setProgress = (position, links) => {
  const loadingBar = document.getElementById('faq-loading-bar')
  if (!!loadingBar) {
    loadingBar.style.height = `${(100 * position) / links.length}%`
  }
}

const FollowingSidebar = ({ titres, children, forceRecalculationKey }) => {
  useEffect(() => {
    const links = [...document.querySelectorAll('#sections h2')]

    function setTableOfContentBackground() {
      document.getElementById('table-of-content-background').style.height =
        document.getElementById('table-of-content').offsetHeight + 'px'
    }

    function calculatePosition() {
      let position = 1
      for (let i = links.length - 1; i >= 0; i--) {
        const titlePosition = links[i].getBoundingClientRect().top
        if (titlePosition <= 120) {
          position = i + 1
          break
        }
      }
      setProgress(position, links)
    }

    document.addEventListener('scroll', calculatePosition)
    window.addEventListener('resize', setTableOfContentBackground)

    setProgress(1, links)

    return () => {
      document.removeEventListener('scroll', calculatePosition)
      window.removeEventListener('resize', setTableOfContentBackground)
    }
  }, [forceRecalculationKey])

  return (
    <div className="relative">
      <div
        id="table-of-content-background"
        className="absolute top-0 left-0 right-0 bg-gray-500 bg-opacity-2"
        style={{ height: `${2 * 48 + titres.length * (24 + 16) - 16}px` }}
      ></div>
      <div className="container relative grid grid-cols-3 gap-3 mx-auto">
        <div className="relative">
          <div className="sticky py-12 pr-6 top-24" id="table-of-content">
            <div className="relative pl-8">
              <div className="absolute top-0 left-0 w-5 h-full bg-gray-500 rounded-full bg-opacity-10">
                <div
                  id="faq-loading-bar"
                  className="absolute top-0 left-0 w-full transition-all bg-green-500 rounded-full"
                ></div>
              </div>
              {titres.map((titre, index) => (
                <a
                  className={`block cursor-pointer ${
                    index < titres.length - 1 && 'mb-4'
                  }`}
                  onClick={() => {
                    document.getElementById(titre.id).scrollIntoView({
                      behavior: 'smooth',
                    })
                  }}
                  key={index}
                >
                  {titre.titre}
                </a>
              ))}
            </div>
          </div>
        </div>
        <div className="col-span-2 py-12" id="sections">
          {children}
        </div>
      </div>
    </div>
  )
}

export default FollowingSidebar
