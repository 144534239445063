import React, { useState } from 'react'
import { StaticQuery, graphql } from 'gatsby'
import getPath from '../utils/getPath'

import SubmenuLink from './SubmenuLink'
import Link from '../components/Link'
import Button from '../components/Button'

import Hamburger from '../assets/images/hamburger.inline.svg'
import CloseMenu from '../assets/images/close-menu.inline.svg'
import Caret from '../assets/images/caret.inline.svg'
import { Transition } from '@headlessui/react'
import { GatsbyImage } from 'gatsby-plugin-image'
import Markdown from '../components/Markdown'

const Menu = () => {
  const [submenu, setSubmenu] = useState(null)
  const [mobileMenu, setMobileMenu] = useState(false)
  return (
    <StaticQuery
      query={graphql`
        query MenuQuery {
          datoCmsMenu {
            contenu {
              ... on DatoCmsBlocMenuLien {
                id
                titre
                model {
                  apiKey
                }
                lien {
                  ... on DatoCmsPage {
                    ...DatoCmsPageLinkFragment
                  }
                  ... on DatoCmsArticle {
                    ...DatoCmsArticleLinkFragment
                  }
                }
              }
              ... on DatoCmsBlocMenuSousMenu {
                id
                titre
                image {
                  alt
                  gatsbyImageData(
                    layout: CONSTRAINED
                    imgixParams: {
                      fit: "crop"
                      crop: "focalpoint"
                      w: "400"
                      h: "400"
                    }
                  )
                }
                paragraphe
                model {
                  apiKey
                }
                colonnes {
                  id
                  titre
                  lien
                  liens {
                    ... on DatoCmsPage {
                      ...DatoCmsPageLinkFragment
                    }
                    ... on DatoCmsArticle {
                      ...DatoCmsArticleLinkFragment
                    }
                  }
                }
              }
              ... on DatoCmsBlocMenuCta {
                id
                model {
                  apiKey
                }
                titre
                lien {
                  ... on DatoCmsPage {
                    ...DatoCmsPageLinkFragment
                  }
                  ... on DatoCmsArticle {
                    ...DatoCmsArticleLinkFragment
                  }
                }
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          {!mobileMenu && (
            <Hamburger
              className="block w-6 h-6 cursor-pointer lg:hidden"
              onClick={() => setMobileMenu(true)}
            />
          )}
          {mobileMenu && (
            <CloseMenu
              className="block w-8 h-8 cursor-pointer lg:hidden"
              onClick={() => {
                setMobileMenu(false)
                setSubmenu(null)
              }}
            />
          )}
          <Transition
            className="fixed bottom-0 left-0 right-0 z-10 block bg-white top-24 lg:hidden"
            show={mobileMenu}
            enter="transition-opacity"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="transition-opacity"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <nav className="lg:hidden">
              {data.datoCmsMenu.contenu.map((block) => {
                if (block.model.apiKey === 'bloc_menu_lien') {
                  return (
                    <Link
                      className="block m-6"
                      to={getPath(block.lien)}
                      key={block.id}
                    >
                      {block.titre}
                    </Link>
                  )
                }
                if (block.model.apiKey === 'bloc_menu_cta') {
                  return (
                    <Button
                      className="ml-4"
                      variant="purple"
                      to={getPath(block.lien)}
                      key={block.id}
                    >
                      {block.titre}
                    </Button>
                  )
                }
                if (block.model.apiKey === 'bloc_menu_sous_menu') {
                  return (
                    <>
                      <a
                        key={block.id}
                        className="flex items-center m-6 cursor-pointer"
                        onClick={() => setSubmenu(block.id)}
                      >
                        <span className="mr-2">{block.titre}</span>
                        <Caret className="transform rotate-90" />
                      </a>
                      <Transition
                        className="absolute inset-0 z-10 overflow-y-auto"
                        show={submenu === block.id}
                        enter="transition-all duration-500"
                        enterFrom="opacity-0 transform translate-x-1/2"
                        enterTo="opacity-100 transform translate-x-0"
                        leave="transition-all duration-500"
                        leaveFrom="opacity-100 transform translate-x-0"
                        leaveTo="opacity-0 transform translate-x-full"
                      >
                        <div className="bg-white">
                          <div className="relative p-6">
                            <GatsbyImage
                              image={block.image.gatsbyImageData}
                              alt={block.image.alt}
                              className="absolute top-0 left-0 w-full h-full"
                              style={{ position: 'absolute' }}
                            />
                            <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50"></div>
                            <div className="relative text-white">
                              <h3
                                className="flex items-center mb-3 font-medium cursor-pointer"
                                onClick={() => setSubmenu(false)}
                              >
                                <Caret className="mr-2 transform -rotate-90 fill-current" />
                                {block.titre}
                              </h3>
                              <Markdown className="text-sm">
                                {block.paragraphe}
                              </Markdown>
                            </div>
                          </div>
                          {block.colonnes.map((colonne) => (
                            <div key={colonne.id} className="p-6">
                              <Link to={colonne.lien}>
                                <h4 className="mb-3 font-bold">
                                  {colonne.titre}
                                </h4>
                              </Link>
                              <ul>
                                {colonne.liens.map((lien, index) => (
                                  <li key={index} className="mb-3 text-sm">
                                    <Link to={getPath(lien)}>{lien.titre}</Link>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          ))}
                        </div>
                      </Transition>
                    </>
                  )
                }
              })}
            </nav>
          </Transition>
          <nav className="items-center hidden lg:flex">
            {data.datoCmsMenu.contenu.map((block) => {
              if (block.model.apiKey === 'bloc_menu_lien') {
                return (
                  <Link
                    className="mx-4"
                    to={getPath(block.lien)}
                    key={block.id}
                  >
                    {block.titre}
                  </Link>
                )
              }
              if (block.model.apiKey === 'bloc_menu_cta') {
                return (
                  <Button
                    className="ml-4"
                    variant="purple"
                    to={getPath(block.lien)}
                    key={block.id}
                  >
                    {block.titre}
                  </Button>
                )
              }
              if (block.model.apiKey === 'bloc_menu_sous_menu') {
                return <SubmenuLink {...block} key={block.id} />
              }
            })}
          </nav>
        </>
      )}
    />
  )
}

export default Menu
