import { graphql } from 'gatsby'
import getPath from '../../getPath'

export default getPath

export const linksFragment = graphql`
  fragment DatoCmsPageLinkFragment on DatoCmsPage {
    id
    slug
    titre
    typeDePage
    model {
      apiKey
    }
  }
  fragment DatoCmsArticleLinkFragment on DatoCmsArticle {
    id
    slug
    titre
    categorie {
      slug
    }
    model {
      apiKey
    }
  }
  fragment DatoCmsLienExterneFragment on DatoCmsLienExterne {
    id
    titre
    url
    model {
      apiKey
    }
  }
`
