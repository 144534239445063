import React from 'react'
import { StaticQuery, graphql } from 'gatsby'

import Card from '../components/Card'
import Link from '../components/Link'
import Logo from '../components/Logo'

import Facebook from '../assets/images/facebook.inline.svg'
import Linkedin from '../assets/images/linkedin.inline.svg'
// import Twitter from '../assets/images/twitter.inline.svg'
import getPath from '../../getPath'

const Blog = () => {
  return (
    <StaticQuery
      query={graphql`
        query FooterQuery {
          datoCmsReglage {
            contenuDuPreFooter {
              id
              titre
              contenu
              ctaOuNewsletter
              texte
              couleur
              fichierCta {
                url
              }
              lienCta {
                ... on DatoCmsPage {
                  ...DatoCmsPageLinkFragment
                }
              }
              image {
                url
                alt
              }
            }
            liensDuFooter {
              ... on DatoCmsBlocFooterContactEtReseauxSociaux {
                model {
                  apiKey
                }
                id
                email
                lienFacebook
                lienLinkedin
                lienTwitter
                titreContact
                titreReseauxSociaux
              }
              ... on DatoCmsBlocFooterListeDeLien {
                model {
                  apiKey
                }
                id
                listeDeLiens {
                  ... on DatoCmsPage {
                    ...DatoCmsPageLinkFragment
                  }
                  ... on DatoCmsArticle {
                    ...DatoCmsArticleLinkFragment
                  }
                  ... on DatoCmsLienExterne {
                    ...DatoCmsLienExterneFragment
                  }
                }
                titre
              }
            }
          }
        }
      `}
      render={(data) => (
        <>
          <div className="py-12">
            <div className="container mx-auto">
              <div className="flex flex-wrap px-4 lg:-mx-6">
                {data.datoCmsReglage.contenuDuPreFooter.map((panneau) => (
                  <Card key={panneau.id} {...panneau} />
                ))}
              </div>
            </div>
          </div>
          <div className="py-12">
            <div className="container px-6 mx-auto">
              <div className="grid gap-2 lg:grid-cols-4">
                <Logo className="mb-6 lg:mb-0" />
                {data.datoCmsReglage.liensDuFooter.map((block, index) => {
                  if (block.model.apiKey === 'bloc_footer_liste_de_lien') {
                    return (
                      <div className="mb-6 lg:mb-0" key={index}>
                        <h4 className="mb-2 font-bold">{block.titre}</h4>
                        <ul>
                          {block.listeDeLiens.map((lien, index) => (
                            <li key={index} className="mb-3 text-sm">
                              {lien.model.apiKey === 'lien_externe' ? (
                                <a href={lien.url} target="_blank">
                                  {lien.titre}
                                </a>
                              ) : (
                                <Link to={getPath(lien)}>{lien.titre}</Link>
                              )}
                            </li>
                          ))}
                        </ul>
                      </div>
                    )
                  }
                  if (
                    block.model.apiKey ===
                    'bloc_footer_contact_et_reseaux_sociaux'
                  ) {
                    return (
                      <div className="mb-6 lg:mb-0" key={index}>
                        <h4 className="mb-2 font-bold">
                          {block.titreReseauxSociaux}
                        </h4>
                        <div className="flex flex-wrap mb-6">
                          <a
                            className="block mr-2"
                            target="_blank"
                            href={block.lienFacebook}
                          >
                            <Facebook />
                          </a>
                          {/* <a
                            className="block mr-2"
                            target="_blank"
                            href={block.lienTwitter}
                          >
                            <Twitter />
                          </a> */}
                          <a
                            className="block"
                            target="_blank"
                            href={block.lienLinkedin}
                          >
                            <Linkedin />
                          </a>
                        </div>
                        <h4 className="mb-2 font-bold">{block.titreContact}</h4>
                        <a className="block" href={`mailto:${block.email}`}>
                          {block.email}
                        </a>
                      </div>
                    )
                  }
                })}
              </div>
            </div>
          </div>
        </>
      )}
    />
  )
}

export default Blog
