import React, { useState } from 'react'
import { useScrollPosition } from '@n8tb1t/use-scroll-position'

import Menu from './Menu'

import Button from '../components/Button'
import Link from '../components/Link'
import Logo from '../components/Logo'

const Header = () => {
  const [isUp, setIsUp] = useState(
    typeof window !== 'undefined' && window.scrollY === 0
  )

  useScrollPosition(({ currPos, prevPos }) => {
    if ((currPos.y === 0) !== (prevPos.y === 0)) {
      setIsUp(currPos.y === 0)
    }
  })

  return (
    <header
      className={`sticky top-0 z-50 flex items-center justify-between h-24 px-4 bg-white ${
        !isUp && 'border-b'
      }`}
    >
      <Link to="/">
        <Logo />
      </Link>
      <Menu />
    </header>
  )
}

export default Header
