import React, { useState } from 'react'
import addToMailchimp from 'gatsby-plugin-mailchimp'

import Loading from '../assets/images/loading.inline.svg'
import getPath from '../../getPath'
import Button from './Button'
// import { Link } from 'gatsby'

const Card = ({
  titre,
  contenu,
  ctaOuNewsletter,
  texte,
  couleur,
  fichierCta,
  lienCta,
  image,
}) => {
  const [email, setEmail] = useState('')
  const [result, setResult] = useState(null)
  const [loading, setLoading] = useState(false)
  return (
    <div
      className={`relative ${
        !!image ? `w-full` : `w-full lg:w-1/2`
      } lg:p-6 mb-6 lg:mb-0`}
    >
      <div
        className={`${
          !image && couleur ? `bg-${couleur}-100` : 'shadow-2xl overflow-hidden'
        } h-full rounded lg:flex`}
      >
        {image && (
          <div
            style={{
              backgroundImage: `url(${image.url})`,
            }}
            className="bg-left-top bg-no-repeat bg-cover pt-1/2 lg:w-1/2 lg:order-2 lg:pt-0"
          ></div>
        )}
        <div
          className={`p-12 ${!image ? 'w-full' : 'w-full lg:w-1/2 lg:order-1'}`}
        >
          <h2 className="mb-6 text-4xl font-bold">{titre}</h2>
          <p className="mb-6">{contenu}</p>
          {ctaOuNewsletter === 'cta' && (
            <Button
              variant={couleur}
              to={lienCta ? getPath(lienCta) : fichierCta?.url}
            >
              {texte}
            </Button>
          )}
          {ctaOuNewsletter === 'newsletter' && (
            <>
              <div className="flex">
                <input
                  type="text"
                  value={email}
                  placeholder={texte}
                  className="w-full px-3 py-2 mr-3 leading-tight text-gray-700 rounded shadow appearance-none focus:outline-none focus:shadow-outline"
                  onChange={(e) => {
                    setEmail(e.target.value)
                  }}
                />
                <Button
                  variant={couleur}
                  disabled={email === ''}
                  onClick={async (e) => {
                    if (email === '') {
                      return
                    }
                    setLoading(true)
                    const result = await addToMailchimp(email)
                    setLoading(false)
                    setResult(result)
                  }}
                >
                  {loading ? (
                    <Loading />
                  ) : (
                    <svg
                      width="28"
                      height="28"
                      xmlns="http://www.w3.org/2000/svg"
                    >
                      <g
                        fill="none"
                        fillRule="evenodd"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="#FFF"
                        strokeWidth="1.667"
                      >
                        <path d="M2.03 15.34a1.06 1.06 0 01.076-1.852L25.48 1.52l-5.653 23.138a1.074 1.074 0 01-.649.741c-.325.13-.692.098-.99-.085L2.03 15.34z" />
                        <path d="M12.96 22.089l-3.235 3.234a.534.534 0 01-.913-.378v-5.417L25.48 1.521" />
                      </g>
                    </svg>
                  )}
                </Button>
              </div>
              {!!result && !!result.msg && (
                <p
                  className="mt-4 mailchimp-response"
                  dangerouslySetInnerHTML={{ __html: result.msg }}
                />
              )}
            </>
          )}
        </div>
      </div>
    </div>
  )
}

export default Card
