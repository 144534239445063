import React, { useState } from 'react'
import { Transition } from '@headlessui/react'

import Caret from '../assets/images/caret.inline.svg'
import getPath from '../utils/getPath'
import Markdown from '../components/Markdown'
import { GatsbyImage } from 'gatsby-plugin-image'
import Link from '../components/Link'

const SubmenuLink = ({ titre, image, paragraphe, colonnes }) => {
  const [shown, showMenu] = useState(false)

  return (
    <a
      className="relative flex flex-wrap items-center mx-4 cursor-pointer"
      onClick={() => {
        showMenu(!shown)
      }}
    >
      <span className={`mr-2 ${shown && 'font-bold'}`}>{titre}</span>
      <Caret
        className={`transform transition-all ${
          shown ? 'rotate-0' : 'rotate-180'
        }`}
      />
      <Transition
        className="fixed bottom-0 left-0 right-0 z-10 cursor-default top-24"
        show={shown}
        enter="transition-opacity"
        enterFrom="opacity-0"
        enterTo="opacity-100"
        leave="transition-opacity"
        leaveFrom="opacity-100"
        leaveTo="opacity-0"
        onClick={() => {
          showMenu(false)
        }}
      >
        <div className="container grid grid-cols-4 mx-auto bg-white shadow-submenu">
          <div className="relative col-span-1 p-12">
            <GatsbyImage
              image={image.gatsbyImageData}
              alt={image.alt}
              className="absolute top-0 left-0 w-full h-full"
              style={{ position: 'absolute' }}
            />
            <div className="absolute top-0 left-0 w-full h-full bg-gray-500 bg-opacity-50"></div>
            <div className="relative text-white">
              <h3 className="mb-3 font-medium">{titre}</h3>
              <Markdown className="text-sm">{paragraphe}</Markdown>
            </div>
          </div>
          <div
            className={`py-12 px-6 gap-6 col-span-3 grid grid-cols-${colonnes.length}`}
          >
            {colonnes.map((colonne) => (
              <div key={colonne.id}>
                <Link to={colonne.lien}>
                  <h4 className="mb-3 font-bold">{colonne.titre}</h4>
                </Link>
                <ul>
                  {colonne.liens.map((lien, index) => (
                    <li key={index} className="mb-3 text-sm">
                      <Link to={getPath(lien)}>{lien.titre}</Link>
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
        </div>
      </Transition>
    </a>
  )
}

export default SubmenuLink
